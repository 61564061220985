<template>
  <div class="FrontPage animate__animated animate__fadeIn">
    <!-- 首页 -->
    <div class="company">
      <video
        muted="muted"
        autoplay="autoplay"
        loop
        src="../assets/vedio/1.mp4"
        alt=""
      />
    </div>
    <div class="fr_about_us">
      <div class="fr_building">
        <img src="../assets/image/index/building.png" alt="" />
      </div>
      <div class="fr_about">
        <div class="fr_about_flex">
          <span style="border-bottom: 5px solid #1d44b3; padding-bottom: 24px"
            >ABO</span
          ><span>UT </span> <span>US</span><br />
          <span>图灵极视（深圳）科技有限公司</span><br />
          <span style="min-width: 592px">
            图灵极视（深圳）科技有限公司是一家由毕业于香港高校的博士团队所创办的科技型<br />初创企业。主要拥有包括视频图像处理、图像识别、机器学习及计算机视觉等领域的<br />技术。
          </span>
          <div class="fr_right">
            <div class="fr_about_flex_last">
              <span @click="about_us">了解更多</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="of_business">
      <div class="scope">
        <div class="scope_"><span>SCOPE</span><span> OF BUSINESS </span></div>
        <hr />
        <span class="category">业务范畴</span>
        <div class="category_box" style="width: 100%; justify-content: center">
          <div class="cate_gory">
            <img src="../assets/image/index/drone.png" alt="" />
            <p>人工智能系统的技术研发</p>
            <span>
              包括：人脸识别、图像识别、物体识别、视频分
              析、应用于无人机和机器人的智能跟随等
            </span>
          </div>
          <div class="cate_gory">
            <img src="../assets/image/index/phone.png" alt="" />
            <p>双目视觉系统的技术研发</p>
            <span
              >可应用于各种显示装置、医疗设备和娱乐设备以增强视觉体验感和光学识别能力</span
            >
          </div>
          <div class="cate_gory">
            <img src="../assets/image/index/face.png" alt="" />
            <p>AR增强现实解决方案及</p>
            <p style="margin: 0 auto; width: 150px; padding: 0px">
              内容定制服务
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="pro_box">
      <div class="product">
        <img src="../assets/image/index/product_babycam.png" alt="" />
        <div class="fr_about_flex">
          <span style="border-bottom: 5px solid #1d44b3; padding-bottom: 26px"
            >PRO</span
          ><span style="font-weight: bold">DUCT </span> <span>INTRODUCTION</span
          ><br />
          <span>图灵宝贝卫士</span><br />
          <span>
            图灵宝贝卫士是一款智能婴儿宝宝监护机。它通过软件和硬件的<br />结合，守护宝宝的安全和健康。强大的智能硬件配上精确的人工<br />智能算法，将科学守护宝宝的健康发挥到极致。本产品不仅在国<br />内各个省份和地区推广，也将远销美国、欧洲以及澳洲等海外国<br />家和地区。
          </span>
          <div class="fr_right">
            <div class="fr_about_flex_last">
              <span @click="babycam">了解更多</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="services">
      <img src="../assets/image/index/services.png" alt="">
    </div> -->
    <div class="services">
      <div class="scope">
        <div class="scope_">
          <span><b>PROJECT</b> SUCCESS STORIES</span>
        </div>
        <hr />
        <span class="category">项目成功案例</span>
        <div class="category_box">
          <div class="category_flex">
            <div class="cate_gory" style="width: 387px; height: 230px">
              <div class="box_bgnone" style="padding-left: 35px">
                <p>智能开发训练平台</p>
                <span class="last_cate">AI后期智能训练平台</span>
              </div>
            </div>
            <div class="cate_gory" style="width: 285px; height: 230px">
              <div class="box_bgnone" style="padding-left: 33px">
                <p>中广核</p>
                <span class="last_cate">广东核电站项目开发</span>
              </div>
            </div>
            <div class="cate_gory" style="width: 184px; height: 230px">
              <div class="box_bgnone" style="padding-left: 17px">
                <p style="font-size: 18px">SR超像素重建</p>
                <span class="last_cate">超分辨率画质修复</span>
              </div>
            </div>
            <div class="cate_gory" style="width: 386px; height: 180px">
              <div class="box_bgnone" style="padding-left: 203px">
                <p>UABB</p>
                <span class="last_cate">深港双年双城展</span>
              </div>
            </div>
            <div class="cate_gory" style="width: 182px; height: 180px">
              <div class="box_bgnone" style="padding-left: 19px">
                <p style="font-size: 18px">动态人脸识别</p>
                <span class="last_cate">实时动态人脸追踪与识别</span>
              </div>
            </div>
            <div class="cate_gory" style="width: 281px; height: 180px">
              <div class="box_bgnone" style="padding-left: 20px">
                <p style="font-size: 18px">无人机跟随和⼿势识别</p>
                <span class="last_cate">AI算法智能跟随</span>
              </div>
            </div>
          </div>
          <div class="category_min_box">
            <div class="min_right" style="width: 285px; height: 100%">
              <div style="padding-top: 279px; padding-left: 20px">
                <p>
                  南方电网智慧<br />
                  电力监控
                </p>
                <span class="last_cate" style="margin-top: 18px; display: block"
                  >数百万级人工智能项目</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="btn_category">
          <span @click="ai_service">了解更多</span>
        </div>
      </div>
    </div>
    <div class="partner">
      <div class="par_scope">
        <div class="par_scope_">
          <span>PARTNERS </span>
        </div>
        <hr />
        <span class="par_category">合作伙伴</span>
        <div class="par_logo">
          <div class="parter_logo">
            <img src="../assets/image/index/par_one.png" alt="" />
          </div>
          <div class="parter_logo">
            <img src="../assets/image/index/par_tow.png" alt="" />
          </div>
          <div class="parter_logo">
            <img src="../assets/image/index/par_three.png" alt="" />
          </div>
          <div class="parter_logo">
            <img src="../assets/image/index/par_four.png" alt="" />
          </div>
          <div class="parter_logo">
            <img src="../assets/image/index/par_five.png" alt="" />
          </div>
          <div class="parter_logo">
            <img src="../assets/image/index/par_six.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  methods: {
    about_us() {
      this.$router.push({ name: "about_us" });
      document.documentElement.scrollTop = "0";
    },
    babycam() {
      this.$router.push({ name: "babycam" });
      document.documentElement.scrollTop = "0";
    },
    ai_service() {
      this.$router.push({ name: "ai_service" });
      document.documentElement.scrollTop = "0";
    },
  },
};
</script>

<style lang="less" scoped>
.FrontPage {
  width: 100%;
  min-width: 1200px;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .company {
    width: 100%;
    // height: 900px;
    display: flex;
    justify-content: center;
    img {
      width: 80%;
      // height: 100%;
    }
    video {
      // width: 100%;
      // height: 100%;
    }
  }
  .fr_about_us {
    width: 100%;
    height: 540px;
    display: flex;
    .fr_building {
      position: relative;
      flex: 1;
      img {
        position: absolute;
        right: 100px;
        top: -120px;
        z-index: 2;
      }
    }
    .fr_about {
      margin-top: -100px;
      padding-left: 40px;
      flex: 1;
      display: flex;
      align-items: center;
      .fr_about_flex {
        font-size: 30px;
        font-weight: bold;
        color: #1d44b3;
        line-height: 45px;
        :first-child {
          line-height: 45px;
        }
        & :nth-child(3) {
          font-size: 30px;
          font-weight: normal;
          color: #1d44b3;
          line-height: 45px;
          padding-bottom: 26px;
          margin-bottom: 30px;
        }
        & :nth-child(5) {
          font-size: 24px;
          font-weight: bold;
          color: #333333;
          line-height: 32px;
          width: 582px;
          display: block;
          margin-top: 60px;
        }
        & > span:nth-child(7) {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          display: block;
          line-height: 32px;
        }
        .fr_right {
          margin-top: 30px;
          display: flex;
          justify-content: flex-end;
          .fr_about_flex_last {
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 40px;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            line-height: 60px;
            background-color: #1d44b3;
            cursor: pointer;
            span {
              font-size: 16px;
              font-weight: 400;
              color: #ffffff;
              line-height: 60px;
            }
          }
          .fr_about_flex_last:hover {
            background-color: #607cca;
          }
        }
      }
    }
  }
  .of_business {
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    .scope {
      width: 100%;
      margin-top: 68px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .scope_ {
        margin: 0 auto;
        :first-child {
          font-size: 30px;
          font-weight: bold;
          color: #1d44b3;
          line-height: 45px;
        }
        & :nth-child(2) {
          font-size: 30px;
          color: #1d44b3;
          line-height: 45px;
        }
      }
      hr {
        border: none;
        margin-top: 26px;
        margin-bottom: 28px;
        width: 60px;
        height: 4px;
        background: #1d44b3;
      }
      .category {
        margin: 0 auto;
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 32px;
      }
      .category_box {
        // margin: 0 auto;
        margin-top: 49px;
        display: flex;
        .cate_gory {
          width: 387px;
          height: 400px;
          margin: 0 9.5px;
          background-color: #ffffff;
          img {
            width: 100%;
            height: 250px;
          }
          p {
            margin: 0 auto;
            width: 220px;
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            // padding-left: 20px;
            padding-top: 20px;
          }
          span {
            display: block;
            width: 336px;
            margin-left: 20px;
            margin-top: 20px;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
          }
        }
      }
    }
  }
  .pro_box {
    display: flex;
    width: 100%;
    justify-content: center;
    .product {
      min-width: 1920px;
      margin-top: 71px;
      width: 1920px;
      height: 600px;
      position: relative;
      display: flex;
      overflow: hidden;
      img {
        pointer-events: none;
        // width: 100%;
        position: absolute;
        bottom: 0px;
      }
      .fr_about_flex {
        margin-left: 360px;
        font-size: 30px;
        color: #1d44b3;
        line-height: 45px;
        :first-child {
          font-size: 30px;
          font-weight: bold;
          color: #1d44b3;
          line-height: 45px;
        }
        & :nth-child(2) {
          font-size: 30px;
          // font-weight: bold;
          color: #1d44b3;
          line-height: 45px;
          padding-bottom: 26px;
          margin-bottom: 30px;
        }
        & :nth-child(5) {
          font-size: 24px;
          font-weight: bold;
          color: #333333;
          line-height: 32px;
          width: 582px;
          display: block;
          margin-top: 60px;
        }
        & > span:nth-child(7) {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          display: block;
          line-height: 32px;
        }
        .fr_right {
          margin-top: 30px;
          display: flex;
          justify-content: flex-start;
          .fr_about_flex_last {
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 40px;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            line-height: 60px;
            background-color: #1d44b3;
            cursor: pointer;
            span {
              font-size: 16px;
              font-weight: 400;
              color: #ffffff;
              line-height: 60px;
            }
          }
          .fr_about_flex_last:hover {
            background-color: #607cca;
          }
        }
      }
    }
  }

  .services {
    width: 100%;
    height: 740px;
    img {
      width: 100%;
    }
  }
  .services {
    width: 100%;
    height: 740px;

    display: flex;
    justify-content: center;
    .scope {
      width: 1920px;
      background-image: url("../assets/image/index/services.png");
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      align-items: center;
      .scope_ {
        margin: 0 auto;
        padding-top: 45px;
        :first-child {
          font-size: 30px;
          color: #fff;
          line-height: 45px;
        }
      }
      hr {
        border: none;
        margin-top: 23px;
        margin-bottom: 28px;
        width: 60px;
        height: 4px;
        background: #fff;
      }
      .category {
        margin: 0 auto;
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        line-height: 32px;
      }
      .category_box {
        width: 1200px;
        height: 430px;
        margin-top: 35px;
        display: flex;
        flex-wrap: wrap;
        .category_flex {
          flex: 3.1 1;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-content: space-between;
          .box_bgnone {
            height: 100%;
            background-image: none !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          & :nth-child(1) {
            background-image: url("../assets/image/index/intelligent.png");
            p {
              font-size: 26px;
              font-weight: bold;
              color: #ffffff;
              background-image: none;
            }
            .last_cate {
              font-size: 14px;
              font-weight: 300;
              color: #ffffff;
              background-image: none;
            }
          }
          & :nth-child(2) {
            background-image: url("../assets/image/index/chimney.png");
          }
          & :nth-child(3) {
            background-image: url("../assets/image/index/mouse.png");
          }
          & :nth-child(4) {
            background-image: url("../assets/image/index/speech.png");
          }
          & :nth-child(5) {
            background-image: url("../assets/image/index/it_face.png");
          }
          & :nth-child(6) {
            background-image: url("../assets/image/index/it_drone.png");
          }
        }
        .category_min_box {
          flex: 1;
          margin-left: 20px;
          .min_right {
            background-image: url("../assets/image/index/antenna.png");
            p {
              font-size: 26px;
              font-weight: bold;
              color: #ffffff;
              background-image: none;
            }
            .last_cate {
              font-size: 14px;
              font-weight: 300;
              color: #ffffff;
              background-image: none;
            }
          }
        }
      }
      .btn_category {
        // padding-bottom: 30px;
        margin-top: 30px;
        display: block;
        width: 120px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1d44b3;
        span {
          cursor: pointer;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 60px;
        }
      }
      .btn_category:hover {
        background-color: #607cca;
      }
    }
  }
  .partner {
    width: 100%;
    height: 360px;
    display: flex;
    justify-content: center;
    .par_scope {
      width: 100%;
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .par_scope_ {
        margin: 0 auto;
        :first-child {
          font-size: 30px;
          font-weight: bold;
          color: #1d44b3;
          line-height: 45px;
        }
      }
      hr {
        border: none;
        margin-top: 25px;
        margin-bottom: 30px;
        width: 60px;
        height: 4px;
        background: #1d44b3;
      }
      .par_category {
        margin: 0 auto;
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 32px;
      }
      .par_logo {
        margin-top: 31px;
        height: 60px;
        display: flex;
        .parter_logo {
          width: 14.87%;
          height: 100%;
          padding: 0 11px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
