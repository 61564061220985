<template>
  <div class="head">
    <div class="logo">
      <img src="../assets/image/index/turingviz.png" alt="" />
    </div>
    <div class="header">
      <div
        class="FrontPage"
        :class="routeName === 'FrontPage' ? 'selected' : 'header_bg'"
        @click="router_head('FrontPage')"
      >
        首页
      </div>
      <div
        class="babycam"
        :class="routeName === 'aimonitor' ? 'selected' : 'header_bg'"
        @click="router_head('aimonitor')"
      >
        AI监护机
      </div>
      <div
        class="ai_service"
        :class="routeName === 'ai_service' ? 'selected' : 'header_bg'"
        @click="router_head('ai_service')"
      >
        AI定制服务
      </div>
      <div
        class="about_us"
        :class="routeName === 'about_us' ? 'selected' : 'header_bg'"
        @click="router_head('about_us')"
      >
        关于我们
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    if ((this.$route.name == null) | undefined | "null" | "") {
      this.$router.push({ name: "FrontPage" });
    }
  },
  computed: {
    routeName() {
      console.log("name", this.$route.name);
      if ((this.$route.name == null) | undefined | "null" | "") {
        return "FrontPage";
      }
      return this.$route.name;
    },
  },
  methods: {
    router_head(event) {
      this.$router.push({ name: `${event}` });
    },
  },
};
</script>

<style scoped lang="less">
.head {
  width: 100%;
  min-width: 1122px;
  height: 80px;
  background-color: #fff;
  display: flex;
  align-items: center;
  .logo {
    flex: 1.1;
    display: flex;
    align-items: center;
    img {
      padding-left: 73px;
    }
  }
  .header {
    flex: 1;
    display: flex;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 80px;
      text-align: center;
      cursor: pointer;
    }
    .FrontPage {
      color: #fff;
    }
    .selected {
      // background-color: #1d44b3;
      color: #1d44b3;
      font-size: 18px;
      font-weight: bold;
    }
    .header_bg {
      background-color: #fff;
      color: #333;
    }
  }
}
</style>
