import Vue from 'vue'
import VueRouter from 'vue-router'
import FrontPage from '../views/FrontPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'FrontPage',
    component: FrontPage
  },
  {
    path: '/aimonitor',
    name: 'aimonitor',
    component: () => import('../views/BabyCam.vue')
  },
  {
    path: '/about_us',
    name: 'about_us',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/ai_service',
    name: 'ai_service',
    component: () => import('../views/AiService.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
