<template>
  <div id="app">
    <Header ref="borther"></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./components/HeadNavigation.vue";
import Footer from "./components/PunilcFooter.vue";
export default {
  components: { Header, Footer },
  // methods: {
  //   router_head($event) {
  //     this.$refs.borther.router_head($event);
  //   },
  // },
};
</script>

<style lang="less">
body {
  // display: flex;
}
#app {
  // width: 100vw;
  min-width: 1122px;
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
}
</style>
