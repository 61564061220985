<template>
  <div class="footer">
    <div class="foot_left">
      <div class="foot_left_text">
        <!-- <div class="foot_head">
          <span @click="ai_service('FrontPage')">首页</span>
          <span @click="ai_service('babycam')">AI监护机</span>
          <span @click="ai_service('ai_service')">AI定制服务</span>
          <span @click="ai_service('about_us')">关于我们</span>
        </div> -->
        <p>
          <span
            style="
              font-weight: bold;
              font-size: 14px;
              border-bottom: 2px solid #7cb1e4;
              padding-bottom: 9px;
              margin-bottom: 9px;
              display: inline-block;
            "
            >联系</span
          ><span style="font-weight: bold; font-size: 14px">我们</span><br />
          <span>微信号: turingviz3 </span> <br /><span>
            邮箱: general@turingviz.com</span
          >
          <br /><span style="width: 360px; display: block; margin-bottom: 21px">
            地址：深圳市龙华区深圳北站新城CBD商务中心汇德大厦1308-1310</span
          >
          <span @click="gotoicp" style="cursor: pointer"
            >粤ICP备2021009401号-1</span
          >
          <!-- <span style="margin-top: 20px; display: block">
            微信号: turingviz3</span
          >
          <span> 邮箱: general@turingviz.com</span> -->
        </p>
      </div>
    </div>
    <div class="foot_right">
      <div class="qr_code">
        <span class="qr_code_bor">京东</span><span>链接</span><br />
        <div class="code-center">
          <img
            style="width: 90px; height: 90px"
            src="../assets/image/index/code.png"
            alt=""
          />
        </div>
      </div>
      <div class="qr_foot">
        <span class="qr_code_bor">AP</span><span>P链接</span><br />
        <div class="code-center">
          <img
            style="width: 90px; height: 90px"
            src="../assets/image/index/T_code.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    ai_service(event) {
      this.$router.push({ name: `${event}` });
      document.documentElement.scrollTop = "0";
    },
    gotoicp() {
      location.href = "https://beian.miit.gov.cn/";
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  // min-width: 1200px;
  height: 340px;
  background-color: #3f3f3f;
  display: flex;
  .foot_left {
    margin-bottom: 30px;
    min-width: 550px;
    margin-left: 105px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .foot_left_text {
      margin-top: -76px;
      width: 35%;
      height: 93px;
      font-size: 12px;
      font-weight: 300;
      color: #ffffff;
      line-height: 24px;
      display: flex;
      flex-direction: column;
      transform: translateY(10px);
      .foot_head {
        display: flex;
        justify-content: space-between;
        padding-bottom: 44px;
        span {
          cursor: pointer;
        }
      }
    }
  }
  .foot_right {
    margin-bottom: 30px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 270px;
    .qr_code {
      width: 100px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      .qr_code_bor {
        border-bottom: 2px solid #7cb1e4;
        padding-bottom: 9px;
      }
    }
    .qr_foot {
      width: 100px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      margin-right: 83px;
      margin-left: 22px;
      .qr_code_bor {
        border-bottom: 2px solid #7cb1e4;
        padding-bottom: 9px;
      }
    }
    .introduce {
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      .qr_code_bor {
        border-bottom: 2px solid #7cb1e4;
        padding-bottom: 9px;
      }
    }
  }
}

.code-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-top: 25px;
  background-color: #fff;
}
</style>
